import React, {Component} from 'react'
import logo from './logo.svg';
import './App.css';

class App extends Component {


    constructor(props) {
        super(props);
        this.thesholdDate = new Date();
        this.thesholdDate.setDate(new Date().getDate() - 30);
        this.state = {
            country: null,
            lastVerified: null
        }
    }

    componentDidMount() {
        let stored_state = JSON.parse(localStorage.getItem("app_state"));
        if (stored_state && stored_state.lastVerified) {
            stored_state.lastVerified = new Date(stored_state.lastVerified);
        }
        this.setState(stored_state, () => {
            if (!this.state.country || !this.state.lastVerified || this.state.lastVerified < this.thesholdDate) {
                fetch('https://ipapi.co/json/')
                    .then(res => res.json())
                    .then((data) => {
                        this.setState({country: data.country, lastVerified: new Date()});
                    })
                    .catch(console.log)
            }
        });
    }

    get coutnriesConfig() {
        return {
            "CH" : {
                "name" : "Adia Switzerland",
                "supportUrl" : "http://ch.help.adia.com"
            }
        }
    }

    isSupportedCountry(country) {
        return Object.getOwnPropertyNames(this.coutnriesConfig).includes(country)
    }

    redirectToCountry(country) {
        let redirect = () => {
            localStorage.setItem("app_state", JSON.stringify(this.state));
            window.location = this.coutnriesConfig[country].supportUrl;
        };

        if (this.state.country !== country) {
            this.setState({country: country, lastVerified: new Date()}, redirect)
        } else {
            redirect();
        }

    }

    redirectToTheRightCountry() {
        if (this.isSupportedCountry(this.state.country) && (!this.state.lastVerified || this.state.lastVerified > this.thesholdDate)) {
            this.redirectToCountry(this.state.country.toUpperCase())
            return this.getLoader();
        } else {
            let countrySelection = [];
            for (const country in this.coutnriesConfig) {
                let countryConfig = this.coutnriesConfig[country];
                countrySelection.push(<button key={country} onClick={() => this.redirectToCountry(country)} >
                    { countryConfig.name }
                </button>)
            }
            return <div>
                <b>You appear to be accessing support from a country in which we don't operate in.<br/> If you intended
                    to reach support in another country check the list below</b>
                <div>
                    {countrySelection}
                </div>
            </div>
        }
    }

    render() {
        return <div className="App">
            <header className="App-header">
                {
                    (this.state.country)
                        ? this.redirectToTheRightCountry(this.state.country)
                        : this.getLoader()
                }
            </header>
        </div>
    }
    ;

    getLoader() {
        return <div id="loader" className="loading-indicator">
            <div className="logo">
                <img src={logo} alt={"Adia Logo"}/>
            </div>
            <div id="fountainG">
                <div id="fountainG_1" className="fountainG"></div>
                <div id="fountainG_2" className="fountainG"></div>
                <div id="fountainG_3" className="fountainG"></div>
                <div id="fountainG_4" className="fountainG"></div>
                <div id="fountainG_5" className="fountainG"></div>
                <div id="fountainG_6" className="fountainG"></div>
                <div id="fountainG_7" className="fountainG"></div>
                <div id="fountainG_8" className="fountainG"></div>
            </div>
        </div>;
    }
}

export default App;
